import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src3530204752/src/web-app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3530204752/src/web-app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3530204752/src/web-app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["Avatar"] */ "/codebuild/output/src3530204752/src/web-app/packages/ds/dist/src/components/Avatar/Avatar.js");
;
import(/* webpackMode: "eager", webpackExports: ["Badge"] */ "/codebuild/output/src3530204752/src/web-app/packages/ds/dist/src/components/Badge/Badge.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3530204752/src/web-app/packages/webapp/components/Carousel/Carousel.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3530204752/src/web-app/packages/webapp/components/CategoryCard/assets/blue.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3530204752/src/web-app/packages/webapp/components/CategoryCard/assets/cyan.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3530204752/src/web-app/packages/webapp/components/CategoryCard/assets/hotpink.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3530204752/src/web-app/packages/webapp/components/CategoryCard/assets/pink.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3530204752/src/web-app/packages/webapp/components/CategoryCard/assets/yellow.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3530204752/src/web-app/packages/webapp/components/CommunityCard/CommunityCard.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3530204752/src/web-app/packages/webapp/components/CookieOption/CookieOption.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Featured"] */ "/codebuild/output/src3530204752/src/web-app/packages/webapp/components/Featured/Featured.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3530204752/src/web-app/packages/webapp/components/Filters/Filters.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3530204752/src/web-app/packages/webapp/components/Hero/Hero.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3530204752/src/web-app/packages/webapp/components/Navbar/Navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TrackingPixel"] */ "/codebuild/output/src3530204752/src/web-app/packages/webapp/components/TrackingPixel/TrackingPixel.tsx");
